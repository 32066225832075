<template>
  <div class="viewcontent">
    <form
      @submit.prevent="processForm()"
      @keyup.enter="processForm()"
      class="regist toolform"
    >
      <p>
        {{ translations.label_recover_password }}
      </p>
      <div class="message error_color" v-if="errorsform.length > 0">
        <div v-if="errorsform.length > 0">
          <p v-for="(error, index) in errorsform" :key="index">
            <span>{{ index + 1 + ': ' + error }}</span>
          </p>
        </div>
      </div>
      <div class="inputcont" :class="checkData('password')">
        <input
          class="border_category tertiary_color"
          type="password"
          autocomplete="on"
          v-model="data.password"
          :placeholder="translations.form_ph_password"
        />
        <button
          type="button"
          @mouseover="hoverWarning('password')"
          @mouseleave="leaveWarning('password')"
        >
          <i class="fas fa-exclamation-triangle warning_color"></i>
        </button>
      </div>
      <div class="warningcont" v-if="warning.password">
        <p>
          {{ translations.warning_password }}
        </p>
      </div>

      <div class="inputcont" :class="checkData('repassword')">
        <input
          class="border_category tertiary_color"
          type="password"
          autocomplete="on"
          v-model="data.secPassword"
          :placeholder="translations.form_ph_retype_password"
        />
        <button
          type="button"
          @mouseover="hoverWarning('repassword')"
          @mouseleave="leaveWarning('repassword')"
        >
          <i class="fas fa-exclamation-triangle warning_color"></i>
        </button>
      </div>
      <div class="warningcont" v-if="warning.repassword">
        <p>
          {{ translations.warning_retype_password }}
        </p>
      </div>

      <button
        class="btn fourth_bg_color contrast_color"
        type="button"
        @click="cancel()"
      >
        {{ translations.btn_cancel }}
      </button>
      <button type="submit" class="btn primary_bg_color contrast_color">
        {{ translations.btn_confirm }}
      </button>
    </form>
  </div>
</template>

<script>
  import router from '../router'
  import md5 from 'js-md5'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'RecoverPassword',
    data() {
      return {
        data: {
          password: null,
          secPassword: null,
        },
        warning: {
          password: false,
          repassword: false,
        },
        errorsform: [],
      }
    },
    computed: {
      ...mapState(['translations']),
    },
    methods: {
      ...mapActions(['changePassword', 'changeToolSelected']),
      processForm() {
        this.errorsform = []
        const rePass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!-_#/%*?&])[A-Za-z\d@$-_/#!%*?&]{10,}$/

        if (
          !this.data.password ||
          (this.data.password && this.data.password.length < 10)
        ) {
          this.errorsform.push(this.translations.error_password)
        }
        if (this.data.password !== this.data.secPassword) {
          this.errorsform.push(this.translations.error_retype_password)
        }

        if (this.errorsform.length === 0) {
          let dataFormated = {
            password: this.encrypt(this.data.password),
            secPassword: this.encrypt(this.data.secPassword),
            code: this.$route.params.id,
          }
          this.changePassword(dataFormated)
        }
      },
      encrypt(pwd) {
        pwd = md5(pwd)
        return pwd
      },
      hoverWarning(type) {
        switch (type) {
          case 'password':
            this.warning.password = true
            break
          case 'repassword':
            this.warning.repassword = true
            break
        }
      },
      leaveWarning(type) {
        switch (type) {
          case 'password':
            this.warning.password = false
            break
          case 'repassword':
            this.warning.repassword = false
            break
        }
      },
      cancel() {
        router.push('/')
      },
      checkData(value) {
        let clas = ''
        let validate = null
        switch (value) {
          case 'password':
            if (this.data.password !== null) {
              validate = this.data.password.length > 10
            }
            break
          case 'repassword':
            if (this.data.secPassword !== null) {
              validate =
                this.data.secPassword.length > 10 &&
                this.data.secPassword == this.data.password
            }
            break
        }
        if (validate !== null && validate) {
          clas = 'valid'
        } else if (validate !== null && !validate) {
          clas = 'invalid'
        }

        return clas
      },
    },
    created() {
      this.changeToolSelected(null)
    },
  }
</script>

<style scope>
  .inputcont {
    position: relative;
  }
  .inputcont button {
    position: absolute;
    top: 40%;
    right: 20%;
    display: none;
    height: auto;
  }
  .invalid button {
    display: block;
  }
</style>
